import { Port } from './models/port.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class PortService {
  constructor(private http: HttpClient) { }

  getAll(page: number, limit: number, headers = null) {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('limit', limit.toString())
      .set('orderby', 'desc')
      .set('column', 'id');

    return this.http
      .get<{ data }>(`${environment.apiUrl}/v1/port/`, { params, headers })
      .pipe(
        map(dataVal => {
          return dataVal.data;
        })
      );
  }

  getPortsForComparision(page: number, limit: number, fetchingExpenditure = 1) {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('limit', limit.toString())
      .set('fetching_expenditure', fetchingExpenditure.toString());

    return this.http
      .get<{ data }>(`${environment.apiUrl}/v1/port/`, { params })
      .pipe(
        map(dataVal => {
          return dataVal.data;
        })
      );
  }

  addPort(port) {
    return this.http.post<any>(`${environment.apiUrl}/v1/port/`, port);
  }

  deletePort(portId) {
    return this.http.delete(`${environment.apiUrl}/v1/port/${portId}`);
  }

  editPort(port: Port, id: number) {
    return this.http.put(`${environment.apiUrl}/v1/port/${id}`, port);
  }

  getByid(id: number) {
    return this.http.get<{ data }>(`${environment.apiUrl}/v1/port/${id}`).pipe(
      map(dataVal => {
        return dataVal.data;
      })
    );
  }

  getByVendor(vendorId, page, limit) {
    return this.http
      .get<{ data }>(`${environment.apiUrl}/v1/company/ports/${vendorId}`)
      .pipe(
        map(dataVal => {
          return dataVal.data;
        })
      );
  }

  getPortSuggestion(search) {
    const url = `${environment.apiUrl}/v1/port/search/${search}`;
    return this.http.get<{ data }>(url).pipe(
      map(dataVal => {
        return dataVal;
      })
    );
  }

  getByShipId(shipId) {
    const url = `${environment.apiUrl}/v1/port/ship/${shipId}`;
    return this.http.get<{ data }>(url).pipe(
      map(dataVal => {
        return dataVal.data;
      })
    );
  }

  exportAll(headers = null) {
    return this.http
      .get(
        `${environment.apiUrl}/v1/port/export?`,
        { headers, responseType: 'blob' }
      )
      .pipe(
        map(data => {
          return data;
        })
      );
  }
}
