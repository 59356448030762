import { BsModalService } from 'ngx-bootstrap/modal';
import { Component, OnInit, Input, TemplateRef, ViewChild, OnChanges, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { Product } from '@app/modules/product/model/product.model';
import { AlertService } from '@app/shared/service/alert.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CatalogueComponent } from '@app/modules/cetalogue/catalogue.component';
import { Router } from '@angular/router';
import { S3Service } from '@app/shared/service/aws-bucket.service';
import { environment } from 'environments/environment';
@Component({
  selector: 'app-product-popup',
  templateUrl: './product-popup.component.html',
  styleUrls: ['./product-popup.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductPopupComponent implements OnInit, OnChanges {
  attachmentModal;
  @Input() product: Product;
  viewTarget: boolean;
  modalRef: any;
  @ViewChild('productPopupModel', { static: false })
  productPopupModel: TemplateRef<any>;
  description: SafeHtml;
  zoomIn = true;
  @ViewChild('attachmentView', { static: false }) attachmentView;
  indicators: boolean = true;
  selectedIndex = 0;
  //controls: boolean = true;
  image = [];
  isReadMore: boolean = true;
  loading: boolean = true;
  imageUrl: string;
  bucket = 'shipskart-dev';
  key = 'assets/images/placeholder_image.png';
  env: any;
  constructor(private modalService: BsModalService,
    private alertService: AlertService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private awsService: S3Service
  ) {
    this.env = environment;
  }

  async ngOnInit() {

  }

  async ngOnChanges(changes: SimpleChanges) {
    if (this.product?.images?.length > 0) {
      if (this.product?.images !== null) {
        this.product.images[0].path = await this.awsService.getS3Object('', this.product.images[0].path).then(res => res)
      }
    } else {
      this.updateProductImages()
        .then((images) => {
          // Handle the successful update of images
          if (this.product)
            this.product.images = images;
        })
        .catch((error) => {
          // Handle the error scenario
          console.error('Error updating product images:', error);
          // Optionally, take additional actions, such as showing a user-friendly message or retrying
        });
    }
  }
  onLoad() {
    this.loading = false;
    this.description = this.sanitizer.bypassSecurityTrustHtml(
      `<p><strong>Description: </strong>${this.product && this.product.description ? this.product.description : ''}</p>`
    );
  }
  //Parse images s3 to convert and return images array 
  async updateProductImages() {
    try {
      // Create an array of promises to fetch and update each image
      if (this.product && this.product?.images !== undefined) {
        const imagePromises = this.product?.images.map(async (img) => {
          img.img_name = await this.awsService.getS3Object(this.bucket, img.img_name);
          return img; // Return the modified image object
        });
        // Wait for all promises to resolve
        const resolvedImages = await Promise.all(imagePromises);
        // Filter out any null values if necessary (e.g., in case of errors)
        this.product.images = resolvedImages.filter(img => img !== null);
        return this.product.images;
      }
    } catch (error) {
      throw error; // Optionally throw the error to handle it further up the chain
    }
  }

  async openModal() {
    this.env = environment;

    //Resolve promise return value
    this.indicators = true;
    this.modalRef = this.modalService.show(
      this.productPopupModel,
      Object.assign(
        { backdrop: 'static', class: 'gray modal-md' }
      )
    );
    this.alertService.backModalClose(this.modalRef);
  }

  attachments() {
    if (this.product.attachment.length == 1) {
      this.router.navigate([]).then(async result => {
        const img = await this.awsService.getS3Object(this.env.bucket, this.product.attachment[0].url).then(res => res);
        window.open(img, '_blank');
      })
    } else {
      this.viewAttachmentModal();
    }
  }

  viewAttachmentModal() {
    this.attachmentModal = this.modalService.show(
      this.attachmentView,
      Object.assign(
        { backdrop: 'static', class: 'gray modal-md' }
      )
    );
    this.alertService.backModalClose(this.modalRef);
  }

  onImgError(event) {
    this.indicators = false;
    this.loading = true;
    event.target.src = '/assets/images/placeholder_image.png';
  }

  selectImage(index: number) {
    this.selectedIndex = index;
  }

  onPrevClick() {
    if (this.selectedIndex === 0) {
      this.image = [];
      this.product.images.forEach(img => {
        this.image.push(img);
        // variant.images.forEach(img => {
        //   this.image.push(img)
        // })
      })
      this.selectedIndex = this.image.length - 1;
    } else {
      this.selectedIndex--;
    }
  }

  onNextClick() {
    if (this.selectedIndex === this.image.length - 1) {
      this.selectedIndex = 0;
    } else {
      this.selectedIndex++;
    }
  }

  showText() {
    this.isReadMore = !this.isReadMore;
  }



  async attachmentUrl(attachment) {
    const img = await this.awsService.getS3Object(this.env.bucket, attachment).then(res => res);
    if (img) {
      window.open(img, "_blank");
    }
  }
}
