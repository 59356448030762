import { Category, SubCategory } from './../models/category.model';
import { environment } from 'environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CategoryService {
  private CategoryList$ = new BehaviorSubject<any>([]);

  constructor(private http: HttpClient) {
  }

  /* getAll(page: number, limit: number) {
    return this.http
      .get<{ data }>(
        `${environment.apiUrl}/v1/category/?page=${page}&limit=${limit}`
      )
      .pipe(
        map(dataVal => {
          sessionStorage.setItem(
            'categoryList',
            JSON.stringify(dataVal.data)
          );
          return dataVal.data;
        })
      );
  } */

  getAllV2(page: any, limit: number) {
    return this.http
      .get<{ data }>(
        `${environment.apiUrl}/v2/category/?page=${page}&limit=${limit}`
      )
      .pipe(
        map(dataVal => {
          sessionStorage.setItem(
            'categoryList',
            JSON.stringify(dataVal.data)
          );
          return dataVal;
        })
      );
  }

  getByIdV2(id: number) {
    return this.http
      .get<{ data }>(`${environment.apiUrl}/v2/category/${id}`)
      .pipe(
        map(dataVal => {
          return dataVal.data;
        })
      );
  }

  addMargin(margin) {
    return this.http.post(`${environment.apiUrl}/v2/category/sub-category/margin/`, margin)
  }
  /* getRequirementCategories(shipId: number) {
    return this.http.get<{ data }>(`${environment.apiUrl}/v1/category/requirement_categories?ship_id=${shipId}`
      ).pipe(map(dataVal => {
          return dataVal.data;
        })
      );
  } */

  // getAllPromise(page: number, limit: number, shipId: number = null) {
  //   return this.http
  //     .get<{ data }>(
  //       `${environment.apiUrl}/v1/category/?page=${page}&limit=${limit}` + (shipId != null ? `&ship_id=${shipId}` : ``)
  //     )
  //     .pipe(
  //       map(dataVal => {
  //         return dataVal.data;
  //       })
  //     ).toPromise();
  // }

  addCategory(category: Category) {
    return this.http.post<Category>(
      `${environment.apiUrl}/v1/category/`,
      category
    );
  }

  mapCategory(req) {
    return this.http.post<Category>(
      `${environment.apiUrl}/v2/category/`,
      req
    );
  }

  addSubCategory(subCategory: SubCategory) {
    return this.http.post<SubCategory>(
      `${environment.apiUrl}/v1/category/sub-category`,
      subCategory
    );
  }

  /* getSubCategory() {
    return this.http
      .get<{ data }>(`${environment.apiUrl}/v1/category/sub-category`)
      .pipe(
        map(dataVal => {
          return dataVal.data;
        })
      );
  } */

  getSubCategoryV2(isRFQParser = false) {
    return this.http
      .get<{ data }>(`${environment.apiUrl}/v2/category/sub-category${isRFQParser ? "?is_rfq_parser=1" : ""}`)
      .pipe(
        map(dataVal => {
          return dataVal.data;
        })
      );
  }

  deleteSubCategory(id: number) {
    sessionStorage.removeItem('categoryList');
    return this.http.delete(
      `${environment.apiUrl}/v1/category/sub-category/${id}`
    );
  }

  deleteMapingCategory(categoryId: number, subCategoryId: number) {
    sessionStorage.removeItem('categoryList');
    return this.http.put(`${environment.apiUrl}/v2/category/delete-mapping/`, {
      'category_id': categoryId,
      'sub_category_id': subCategoryId
    });
  }

  editCategory(category: Category) {
    return this.http.put(`${environment.apiUrl}/v1/category/`, category);
  }

  getByid(id: number) {
    return this.http
      .get<{ data }>(`${environment.apiUrl}/v1/category/${id}`)
      .pipe(
        map(dataVal => {
          return dataVal.data;
        })
      );
  }

  getSubcatByid(id: number) {
    return this.http
      .get<{ data }>(`${environment.apiUrl}/v1/category/sub-category/${id}`)
      .pipe(
        map(dataVal => {
          return dataVal.data;
        })
      );
  }

  getLatestProductCode(categoryId = null, subCategoryId = null): Observable<any> {
    const params = {};
    if (categoryId != null) {
      params['category_id'] = categoryId;
    }
    if (subCategoryId != null) {
      params['sub_category_id'] = subCategoryId;
    }
    return this.http.get(`${environment.apiUrl}/v1/category/latest_product_code`, { params: params });
  }

  /* getSubCategoryExpenditureReport(categoryId, companyId = null, vendorId = null, shipId = null, portId = null, fromDate = null, toDate = null, download = 0, format = 'json') {
    let params = new HttpParams();
    params = params.append('category_id', categoryId);
    params = params.append('format', format);
    params = params.append('download', download.toString());
    if (companyId != null) {
      params = params.append('company_id', companyId);
    }
    if (vendorId != null) {
      params = params.append('vendor_id', vendorId);
    }
    if (fromDate != null && toDate != null) {
      params = params.append('from_date', fromDate);
      params = params.append('to_date', toDate);
    }
    if (shipId) {
      params = params.append('ship_id', shipId);
    }
    if (portId) {
      params = params.append('port_id', portId);
    }
    return this.http.get(
      `${environment.apiUrl}/v1/report/subcategory-expenditure`, {params}
    );
  } */

  getCategoryExpenditureReport(companyId = null, vendorId = null, shipId = null, portId = null, fromDate = null, toDate = null, categoryIds = null, download = 0, format = 'json', subReport = 0, subReportCategoryId = null) {
    let params = new HttpParams();
    params = params.append('format', format);
    params = params.append('download', download.toString());
    params = params.append('sub_report', subReport.toString());
    if (subReportCategoryId != null) {
      params = params.append('sub_report_category_id', subReportCategoryId.toString());
    }
    if (companyId != null) {
      params = params.append('company_id', companyId);
    }
    if (vendorId != null) {
      params = params.append('vendor_id', vendorId);
    }
    if (shipId) {
      params = params.append('ship_id', shipId);
    }
    if (portId) {
      params = params.append('port_id', portId);
    }
    if (fromDate != null) {
      params = params.append('from_date', fromDate);
    }
    if (toDate != null) {
      params = params.append('to_date', toDate);
    }
    if (categoryIds != null) {
      params = params.append('category_id', categoryIds);
    }
    if (download) {
      return this.http.get(`${environment.apiUrl}/v1/report/category-expenditure`, { params, responseType: 'blob' });
    } else {
      return this.http.get(`${environment.apiUrl}/v1/report/category-expenditure`, { params: params },
      );
    }
  }

  getCategoryExpenditureReportMulti(companyId = null, shipIds = [], portIds = [], fromDates = [], toDates = [], categoryIds = [], download = 0, format = 'json') {
    let params = new HttpParams();
    params = params.append('format', format);
    params = params.append('download', download.toString());

    if (companyId != null) {
      params = params.append('company_id', companyId);
    }
    shipIds.forEach(shipId => {
      params = params.append('ship_id', shipId);
    });
    portIds.forEach(portId => {
      params = params.append('port_id', portId);
    });

    fromDates.forEach(fromDate => {
      params = params.append('from_date', fromDate);
    });

    toDates.forEach(toDate => {
      params = params.append('to_date', toDate);
    });

    categoryIds.forEach(categoryId => {
      params = params.append('category_id', categoryId);
    });

    if (download) {
      return this.http.get(`${environment.apiUrl}/v1/report/category-expenditure-multi`, { params, responseType: 'blob' });
    } else {
      return this.http.get(`${environment.apiUrl}/v1/report/category-expenditure-multi`, { params: params },
      );
    }
  }

  getCategoryWiseOrderReport(shipId = null, fromDate = null, toDate = null, reportType = null, status = null, subReportCategoryId = null) {
    let params = new HttpParams();
    if (subReportCategoryId != null) {
      params = params.append('sub_report_category_id', subReportCategoryId.toString());
    }
    if (shipId) {
      params = params.append('ship_id', shipId);
    }
    if (fromDate != null && toDate != null) {
      params = params.append('from_date', fromDate);
      params = params.append('to_date', toDate);
    }
    if (reportType != null) {
      params = params.append('is_order', reportType);
    }
    if (status != null) {
      params = params.append('status', status);
    }
    return this.http.get(`${environment.apiUrl}/v1/report/category-wise-order-report`, { params: params });
  }

  /* getSubCategoryWiseOrderReport(categoryId, shipId, status, fromDate = null, toDate = null, reportType = null) {
    let params = new HttpParams();
    params = params.append('cat_id', categoryId);
    if (fromDate != null && toDate != null) {
      params = params.append('from_date', fromDate);
      params = params.append('to_date', toDate);
    }
    if (shipId) {
      params = params.append('ship_id', shipId);
    }
    if (status) {
      params = params.append('status', status);
    }
    if (reportType) {
      params = params.append('is_order', reportType);
    }
    return this.http.get(
      `${environment.apiUrl}/v1/report/subcategory-wise-order-report`, {params}
    );
  } */
}
