import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild, Renderer2, ElementRef } from '@angular/core';
import { AbstractControl, FormBuilder, FormsModule, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, first } from 'rxjs/operators';
import { ProductService } from '@app/modules/product/services/product.service';
import { SharedService } from '@shared/service/shared.service';
import { CategoryService } from '@app/modules/category/services/category.service';
import { Product } from '@app/modules/product/model/product.model';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { UserRoles } from '@app/core/enums/user-roles';
import { PortService } from '@app/modules/port/services/port.service';
import { ChangeDetectorRef } from '@angular/core';
import { DecryptService } from '@app/shared/service/decrypt.service';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-product-request',
  templateUrl: './product-request.component.html',
  styleUrls: ['./product-request.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductRequestComponent implements OnInit {
  @Output() closeModalClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() selectProduct: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public automaticparse = new EventEmitter<any>();
  @Input() requisition = [];
  @Input() rfqParserProduct = null;
  @ViewChild('autoComplete', { read: ElementRef }) autoComplete: ElementRef;
  newProductForm: FormGroup;
  source = 'WEB';
  file: File;
  packType = 1;
  quantity = 1;
  newProductFormSubmitted = false;

  page = 1;
  limit = 10;
  suggestion: any[] = [];
  categoryList = [];
  subCategoryList = [];
  units = [];
  @Input() shipId: number = null;
  @Input() reqId: number = null;
  @Input() updateSpecific = false;
  @Input() addSpecific = false;
  @Input() categoryId: number = null;
  @Input() portId: number = null;
  @Input() roleId: number = null;
  @Input() isPlaceReq: boolean = false;
  @Input() isPlaceOrder: boolean = false;
  @Input() isProvisionCart: boolean = false;
  @Input() isCatalogue: boolean = false;
  @Output() updateItem = new EventEmitter<{}>();
  submitted: boolean;
  selectedAutocomplete: string = null;
  searchForm: FormGroup;
  pageLoading: boolean;
  productList: Product[] = [];
  total: number;
  noProduct: any;
  selectedSubCategory = 0;
  progress = false;
  userRoles = UserRoles;
  loading2: boolean;
  portList = [];
  formData: FormData;
  currentPortId: number;
  requisitionList = [];
  unit_id = null;
  product_name = '';
  activeRoute: string;
  showReqField: boolean = true;
  keyPressed: String = '';
  impaCode: String = '';
  brandList: any;
  isLoading: boolean = false;
  brandName: string = '';
  searchSubject: Subject<string> = new Subject<string>();
  constructor(
    private formBuilder: FormBuilder,
    private productService: ProductService,
    private alertService: ToastrService,
    private sharedService: SharedService,
    private categoryService: CategoryService,
    private portService: PortService,
    private router: Router,
    private toastr: ToastrService,
    private ref: ChangeDetectorRef,
    private route: ActivatedRoute,
    private decrypt: DecryptService
  ) {
    this.searchSubject
      .pipe(
        debounceTime(300), // Wait for 300ms of inactivity
        distinctUntilChanged() // Ignore duplicate values
      )
      .subscribe(query => {
        const qer = query.trim();
        this.fetchSuggestions(qer);
      });
  }

  ngOnInit() {
    if (this.rfqParserProduct != null) {
      this.impaCode = this.rfqParserProduct.pro_code;
      this.product_name = this.rfqParserProduct != null ? this.rfqParserProduct['description'] : '';
      this.quantity = this.rfqParserProduct['new_qty'];
    }
    this.buildNewProductRequestForm();
    this.newProductForm.patchValue({ name: this.product_name, description: this.product_name, quantity: this.quantity })
    this.getUnit();
    this.buildSearchForm();
    this.getBrands();

    if (!this.portId) {
      this.getPortList();
    }
    if (this.categoryId == null) {
      this.getSubCategory();
    } else {
      if (this.rfqParserProduct != null) {
        this.getSubCategory();
      } else {
        this.getCategory();
      }
    }

    const id = this.route.snapshot.params['id'];
    if (this.router.url == `/requisitions/detail/${id}`) {
      this.showReqField = false;
      this.createReqFormValid();
    }
  }

  buildSearchForm() {
    this.searchForm = this.formBuilder.group({
      search: ['', [Validators.required]],
      category_id: [null]
    });
  }

  buildNewProductRequestForm() {
    this.newProductForm = this.formBuilder.group({
      name: [null, [Validators.required]],
      description: [null, [Validators.required]],
      category_id: [this.categoryId, [Validators.required]],
      sub_category_id: [null, [Validators.required]],
      requirement_id: [null, [Validators.required]],
      unit_id: [this.unit_id, [Validators.required]],
      img_name: [''],
      source: [this.source],
      pack_type: [this.packType, Validators.compose([Validators.required, this.positiveNumberValidator()])],
      quantity: [null, Validators.compose([Validators.required, this.positiveNumberValidator()])],
      ship_id: [''],
      req_id: [this.reqId],
      impa_code: [this.impaCode],
      brand_name: [null],
      brand_id: [null]
    });
    this.requsitionList();
  }
  changeQuantity(event) {
    this.quantity = (event.target.value);
    this.newProductForm.patchValue({ quantity: this.quantity });
    this.newProductForm.get('quantity').setValue(this.quantity);

  }
  positiveNumberValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const isNotOk = Number(control.value) <= 0;
      return isNotOk ? { nonPositive: { value: control.value } } : null;
    };
  }

  getPortList() {
    this.loading2 = true;
    this.portService.getAll(1, 1000).subscribe(res => {
      this.portList = res.results.filter(x => x.status === 1 && x.is_active === 1);
      this.loading2 = false;
    }, error => {
      this.loading2 = false;
    });
  }

  getCategory() {
    const page = this.page;
    // const shipId = this.shipId;
    this.categoryService.getAllV2(page, 20).subscribe(data => {
      if (data.data && data.data.length > 0) {
        if (this.roleId == this.userRoles.ROLE_CAPTAIN && !this.isPlaceReq && this.isPlaceOrder && !this.isCatalogue) {
          if (this.isProvisionCart) {
            this.categoryList = data.data.filter(x => x.id === 61);
          } else {
            this.categoryList = data.data.filter(x => x.id !== 61);
          }
        } else {
          if (this.roleId == this.userRoles.ROLE_CAPTAIN && !this.isPlaceReq && this.isPlaceOrder) {
            this.categoryList = data.data.filter(x => x.id === 61);
          } else {
            this.categoryList = data.data;
          }
        }
        if (this.categoryId && this.rfqParserProduct == null) {
          this.categoryList = this.categoryList.filter(cat => cat.id === this.categoryId);
          if (this.categoryList && this.categoryList.length > 0 && this.categoryList.length < 2) {
            this.subCategoryList = this.categoryList[0].sub_categories;
          }
        } else {
          if (this.rfqParserProduct != null) {
            // this.newProductForm.get('category_id').setValue(this.categoryList[0].id);
            this.newProductForm.patchValue({
              category_id: this.categoryId ? this.categoryId : this.categoryList[0].id,
            });
            var subcategory_id = this.subCategoryList.filter(x => { return x.name == 'Unknown'; })[0].id
            this.newProductForm.patchValue({
              sub_category_id: subcategory_id,
            });
            // this.newProductForm.get('sub_category_id').setValue(this.categoryList[0].sub_categories[0].id)
          }
        }
      }
    });
  }
  getSubCategory() {
    this.categoryService.getSubCategoryV2(this.rfqParserProduct != null ? true : false).subscribe(data => {
      data.map(x => { x.active = false; });
      this.subCategoryList = data;
      this.getCategory();
    });
  }

  toSentenceCase = (camelCase: string): string => {
    if (camelCase) {
      const result = camelCase.replace(/([A-Z])/g, '$1');
      return result[0].toUpperCase() + result.substring(1).toLowerCase();
    }
    return '';
  };

  async getUnit() {
    await this.fetchUnit();
  }

  fetchUnit() {
    this.productService.getUnits(500).subscribe(data => {
      this.units = data;
      if (this.rfqParserProduct != null) {
        var unitId = null;
        for (var item of this.units) {
          if (this.rfqParserProduct['unit'].toLowerCase() == item['name'].toLowerCase()) {
            this.newProductForm.patchValue({
              unit_id: item['id'],
            });
            unitId = item['id'];
            break;
          }
        }
        if (unitId == null) {
          var unit = this.toSentenceCase(this.rfqParserProduct['unit']);
          const formData = new FormData();
          formData.append('name', unit);
          formData.append('is_deleted', 'true');
          formData.append('display_name', unit);
          this.productService.createUnits(formData).then(
            dt => {
              this.units.push(dt['data']);
              this.newProductForm.patchValue({
                unit_id: dt['data']['id'],
              });
              this.newProductForm.get('unit_id').setValue(dt['data']['id']);

            },
            error => {
              this.toastr.error('Something Went Wrong', error.error);
            }
          );
        }
      }
    });
  }


  closeModal() {
    this.closeModalClicked.emit(false);
  }

  addNewProductRequest() {
    if (!this.rfqParserProduct) {
      this.newProductForm.removeControl('impa_code')
    }
    this.newProductFormSubmitted = true;
    if (this.updateSpecific) {
      this.createReqFormValid();
    }
    if (!this.newProductForm.valid) {
      return;
    }
    this.formData = this.sharedService.convertModelToFormData(
      this.newProductForm.value
    );
    if (this.formData.get('img_name')) {
      this.formData.append('image', this.file, this.file.name);
    }
    if (this.currentPortId) {
      this.portId = this.currentPortId;
    }
    if (this.roleId == this.userRoles.ROLE_CAPTAIN && this.isPlaceOrder && this.newProductForm.value.category_id != null && this.newProductForm.value.category_id === 61) {
      if (this.portId != null) {
        this.formData.append('port_id', this.portId.toString());
      } else {
        this.toastr.error('Please select port first!');
        return false;
      }
    }
    this.progress = true;
    this.productService
      .addProductRequest(this.formData)
      .pipe(first())
      .subscribe(
        productData => {
          productData.data.quantity = this.quantity;
          this.automaticparse.emit(false);
          this.closeModalClicked.emit(true);
          this.progress = false;
          this.selectProduct.emit(false);
          this.updateItem.emit(productData);
          this.formData = new FormData();
          // this.updateCart();
        }, error => {
          this.formData = new FormData();
          this.alertService.error(error.error);
          this.progress = false;
        }
      );
  }

  selectedfileName(files: FileList) {
    if (files.length > 0) {
      this.file = files[0];
    }
  }

  searchProduct() {
    // this.submitted = true;
    this.selectedAutocomplete = this.searchForm.value.search;
    this.page = 1;
    this.getProduct(this.selectedSubCategory, this.selectedAutocomplete);
    // this.suggestion = [];
  }

  getProduct(id = 0, search = this.selectedAutocomplete) {
    // this.selectedSubCategory = id;
    this.pageLoading = true;
    const page = this.page;
    const limit = this.limit;
    // search = search ? search.replace(/[^a-zA-Z ]/g, "") : null;
    this.productService.getByCategoryId(id, 0, page, limit, search).subscribe(
      data => {
        const tempJson = JSON.parse(this.decrypt.decryptUsingAES256(data));
        this.productList = [...tempJson[0]['data']['results']];
        this.total = tempJson[0]['data'].total;
        this.noProduct = this.total === 0 ? 'No Product Found' : '';
        this.productList.map(product => {
          product.quantity = 0;
          product.product_id = product.id;
          product.ship_id = this.shipId;
          product.category_id = product.category?.id ? product.category.id : null;
        });
        this.pageLoading = false;
        // this.updateCart();
        // this.categoryName = this.getCategoryName();
      }, error => {
        this.pageLoading = false;
      }
    );
  }

  search(event) {
    this.searchSubject.next(event.query);
  }

  doOnSelect(event) {
    this.selectedAutocomplete = event.name;
    this.newProductForm.patchValue({ name: this.selectedAutocomplete });
    if (this.addSpecific) {
      this.automaticparse.emit(false)
      this.selectProduct.emit(true);
      this.updateItem.emit(event);
      this.closeModal();
    }
    if (this.showReqField && !this.updateSpecific) {
      this.router.navigate(['/dashboard/catalogue'], { queryParams: { q: event.name } });
    }
  }
  changeCategory(event) {
    this.subCategoryList = event.sub_categories;
    if (this.rfqParserProduct == null) {
      this.newProductForm.patchValue(
        { sub_category_id: null }
      );
    }
  }


  changeSubCategory(event) {

    this.newProductForm.patchValue({ sub_category_id: event.id });
    this.newProductForm.get('sub_category_id').setValue(event.id);
  }

  changeUnit(event) {
    this.newProductForm.patchValue({ unit_id: event.id });
    this.newProductForm.get('unit_id').setValue(event.id);
  }
  createdrequisition: string;
  requsitionList() {
    this.requisition && this.requisition.forEach(item => {
      this.createdrequisition = `${item.requirement_name} ${item.category.name} <br/> Created By: ${item.creator_name} / ${item.rank}`
      this.requisitionList.push({ requirement_id: item.id, name: item.requirement_name, rank: item.rank, category_id: item.category_id, category_name: item.category.name, creator_name: item.creator_name });
    });
  }
  changeRequisition(event) {
    this.requisition.forEach(req => {
      if (req.requirement_name == event.name) {
        this.createdrequisition = `${event.name} <br/> Created By: ${event.creator_name} / ${event.rank}`
        this.newProductForm.get('requirement_id').setValue(event.requirement_id);
        this.newProductForm.get('category_id').setValue(event.category_id);
        this.newProductForm.get('sub_category_id').setValue(null)
        this.categoryId = event.category_id
        this.getCategory()
      }
    })
  }

  createReqFormValid() {
    if (this.updateSpecific || !this.showReqField) {
      this.newProductForm.get('requirement_id').clearValidators();
      this.newProductForm.get('requirement_id').updateValueAndValidity();
    }
  }

  //Get all brand list
  getBrands() {
    this.isLoading = true;
    this.sharedService.getAllBrands().subscribe((val) => {
      if (val) {
        this.isLoading = false;
        return this.brandList = val
      }
    }, (error) => {
      this.toastr.error(error)
    })
  }
  changeBrand(event) {
    if (event.id) {
      this.brandName = event.name;
      this.newProductForm.get('brand_id').patchValue(event.id);
    } else {
      this.newProductForm.get('brand_name').patchValue(event.name);
      this.brandName = event.name;
    }
  }

  fetchSuggestions(query: string): void {
    this.productService
      .getProductSuggestion(this.selectedSubCategory, query, this.categoryId, this.portId, 0)
      .subscribe(
        data => {
          const tempJson = JSON.parse(this.decrypt.decryptUsingAES256(data));
          if (tempJson[0]['data']) {
            tempJson[0]['data'].map(x => {
              x.name = x._source.name;
              x.impa_code = x._source?.impa_code;
              x.pack_type = x._source?.pack_type;
              x.brand_name = x._source?.brand?.name;
              x.unit =
                x._source.unit && x._source?.unit?.name
                  ? x._source?.unit?.name
                  : x._source.unit
                    ? x._source.unit
                    : '';
            });
            this.suggestion = tempJson[0]['data'];
          }
        },
        error => {
          this.suggestion = [];
        }
      );
  }
}
