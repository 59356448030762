<div class="fluid-container card-container for-less-than-two-data-cards" draggable="true">
  <ng-container *ngFor="let columnKey of data; let i = index">
    <!-- <pre>{{data|json}}</pre> -->
    <!-- Data Card Started -->
    <div class="body row" draggable="true">
      <div class="quantity-stats">
        <div class="card-head">
          <h3 class="sub-head-inner">{{columnKey.report_title | capitalize}}</h3>
          <i class="btn" data-toggle="tooltip" data-placement="top" *ngIf="checkData(i,1)">
            <span class="fa fa-info-circle icon-style" placement="top"
            [ngbPopover]="checkData(i,2)"
            triggers="mouseenter:mouseleave"></span>
          </i>
          <a class="more-btn more-link"
            *ngIf="(data[i].filters.length>0 || data[i].user_id)"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false" href="javascript:void(0)">
            <span class="material-symbols-outlined">
              menu
            </span>
          </a>
          <div class="dropdown-menu float-right"
            aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item"
              href="javascript:void(0)"
              (click)="openFilterModal(i)"
              *ngIf="data[i].filters.length>0"><i class="fa fa-filter"></i> Filter</a>
            <a class="dropdown-item"
              href="javascript:void(0)" *ngIf="data[i].user_id"
              (click)="openPanelModal(i,true)"><i class="fa fa-edit"></i> Edit</a>
            <a class="dropdown-item"
              href="javascript:void(0)"
              (click)="panelDeleteModal(i,deletePanelModal)"
              *ngIf="data[i].user_id"><i class="fa fa-trash"></i> Delete</a>
          </div>
        </div>
        <a class="card-value" (click)="fetchValue(columnKey)" [ngClass]="{'cursor-pointer': columnKey.navigation_type, 'default-cursor':!columnKey.navigation_type}">
          <h2 class="sub-head-counts purchase-order"
          [title]="columnKey.column_keys[0]">{{ columnKey?.column_values[0] ? (columnKey?.column_values[0]?.toLocaleString() | twoDigitNumber) : ''}}</h2>
        </a>
      </div>
    </div>
  </ng-container>
  <!-- Data Card End -->
</div>

<!-- Filter Modal Started -->
<ng-container *ngIf=" (data[index] && data[index].filters && data[index].filters.length>0) && (index==0 ||index) ">
  <app-filter [data]="data[index].filters" [otherAxis]="data[index]" #filterData (filterApplied)="filterAppied($event)"></app-filter>
</ng-container>
<!-- <ng-template #filterModal>
  <div class="modal-header ">
    <h3 class="text-center">Filter</h3>
  </div>
  <div class="modal-body">
    <ng-container *ngFor="let filter of data[index].filters">
      <div class="row">
        <div class="col-md-10 form-group">
          <label for="">{{filter.filter.name}}</label>
          <ng-select [items]="filter.filter_options"
            bindLabel="name"
            bindValue="id"
            placeholder="{{'Select '+filter.filter.name }}"
            [multiple]='filter.filter.type == "single_select" ? false : true'
            class="form-control"></ng-select>
        </div>
        <div class="col-md-10">
          <label for="">{{filter.filter.name}}</label>
          <ng-select [items]="filter.filter_options"
            bindLabel="name"
            bindValue="id"
            placeholder="{{'Select '+filter.filter.name }}"
            [multiple]='filter.filter.type == "single_select" ? false : true'
            class="form-control"></ng-select>
        </div>
      </div>

    </ng-container>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary"
      (click)="modal.hide()">Cancel</button>
    <button class="btn btn-primary">Filter</button>
  </div>
</ng-template> -->
<!-- Filter Modal End -->
<ng-container *ngIf="editPanel">
<app-panel [edit]="editPanel"
  [sharedData]="sharedData"
  #panelData (callParentMethodEvent)="reloadPage(event)"></app-panel>
</ng-container>

<ng-template #deletePanelModal>
  <div class="modal-body cancelmodal-spc text-center ">
    <h4 class="sure-font">Are you sure to Delete?</h4>
    <div class="actionbuttons tmargin30">
      <button class="btn btn-outline-default cancel-btn"
        (click)="deleteCardModal.hide()">No</button>
      <button class="btn btn-primary"
        (click)="deletePanel()">
        <span class="spinner-border mt-1 mr-1 spinner-border-sm"
                *ngIf="loadingDeleteModal"
                role="status"
                aria-hidden="true"></span>
              <span class="sr-only">Loading...</span>Yes</button>
    </div>
  </div>
</ng-template>