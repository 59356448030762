import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { AuthenticationService } from '../../authentication/services/authentication.service';
import { ETSTEUR } from 'environments/environment';
import { YETTSS } from 'environments/environment'
@Injectable({
  providedIn: 'root',
})
export class DecryptService {
  private key: string;
  private iv: string;
  constructor(private authService: AuthenticationService) {
    this.getProductData()
  }

  getProductData() {
    this.authService.productHandler({ 'k_1': ETSTEUR, 'k_2': YETTSS }).subscribe(res => {
      this.key = CryptoJS.enc.Utf8.parse(res['key']);
      this.iv = CryptoJS.enc.Utf8.parse(res['iv']);
    })
  }

  decryptUsingAES256(encryptedText: string): string {
    try {
      const cipherParams = CryptoJS.lib.CipherParams.create({
        ciphertext: CryptoJS.enc.Hex.parse(encryptedText),
      });
      const decrypted = CryptoJS.AES.decrypt(cipherParams, this.key, {
        iv: this.iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });
      return decrypted.toString(CryptoJS.enc.Utf8).trim();
    } catch (e) {
      console.error('Decryption error', e);
      return '';
    }
  }
}
