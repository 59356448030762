import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';


@Injectable({ providedIn: 'root' })
export class RfqService {
  constructor(private http: HttpClient) { }
  rfqsCount(vendorId: number) {
    return this.http
      .get<{ data }>(`${environment.apiUrl}/v1/rfq/?vendor_id=${vendorId}`)
      .pipe(
        map(count => {
          return count;
        })
      );
  }
  getAll(params) {
    return this.http
      .get<{ data }>(`${environment.apiUrl}/v1/rfq/`, { params })
      .pipe(
        map(dataVal => {
          return dataVal.data;
        })
      );
  }
  getById(id: number) {
    return this.http
      .get<{ data }>(`${environment.apiUrl}/v1/rfq/${id}`)
      .pipe(
        map(dataVal => {
          return dataVal.data;
        })
      );
  }
  updatePrice(payload, isOew?) {
    return this.http.post<any>(
      `${environment.apiUrl}/v1/product/${isOew ? 'oew_price' : 'price'}`,
      payload
    );
  }
  editPrice(payload, isOew) {
    return this.http.put<any>(
      `${environment.apiUrl}/v1/product/${isOew ? 'oew_price' : 'price'}`,
      payload
    );
  }

  deleteRequest(id) {
    return this.http.delete<any>(`${environment.apiUrl}/v1/product/price?id=${id}`);
  }

  getSourceCharges(vendorId, portId, sourceId) {
    return this.http.get(`${environment.apiUrl}/v1/product/get_default_source_location_charges?vendor_id=${vendorId}&port_id=${portId}&sourcing_location_id=${sourceId}`);
  }

  uploadProduct(payload) {
    const headers = new HttpHeaders();
    // this is the important step. You need to set content type as null
    headers.set('Content-Type', null);
    headers.set('Accept', 'multipart/form-data');
    return this.http.post<any>(
      `${environment.apiUrl}/v1/vendor/excel-import`,
      payload,
      { headers }
    );
  }

  exportProduct(vendorId: number, portId: number, type, subCategoryId, isRfq, params = null, isOew) {
    const header = new HttpHeaders().set('x-file', [type]);
    let url = `${environment.apiUrl}/v1/vendor/download/`;
    if (vendorId) {
      url = `${environment.apiUrl}/v1/vendor/download/${vendorId}?is_rfq=${isRfq}`;
    }
    if (vendorId && portId && subCategoryId) {
      url = `${environment.apiUrl}/v1/vendor/download/${vendorId}/${portId}/${subCategoryId}?is_rfq=${isRfq}&is_oew=${isOew}`;
    }
    return this.http.get(url, { params, headers: header, responseType: 'blob' }).pipe(
      map(dataVal => {
        return dataVal;
      })
    );
  }

  submitRfq(payload) {
    return this.http.post(`${environment.apiUrl}/v1/rfq/`, payload);
  }

  getRfqExpiredProducts(params) {
    return this.http.get<{ data }>(`${environment.apiUrl}/v1/rfq/product_prices_expiry`, { params })
      .pipe(map(dataVal => {
        return dataVal.data;
      })
      );
  }
  getExpiryProductList(params, headers) {
    return this.http
      .get<{ data }>(`${environment.apiUrl}/v1/rfq/product_prices`, {
        headers,
        params,
      })
      .pipe(
        map(requisitions => {
          return requisitions.data;
        })
      );
  }

  getExpiryProductListExport(params, headers) {
    return this.http
      .get(`${environment.apiUrl}/v1/rfq/product_prices`, {
        headers,
        params,
        responseType: 'blob'
      })
      .pipe(
        map(requisitions => {
          return requisitions;
        })
      );
  }

  submitRfqPrice(payload) {
    return this.http.post(`${environment.apiUrl}/v1/rfq/product_prices`, payload)
  }

  getShipskartRFQ(params, vendorId) {
    return this.http.get<{ data }>(`${environment.apiUrl}/v1/rfq/quotations_from_shipskart/${vendorId}`, { params })
      .pipe(map(dataVal => {
        return dataVal.data;
      })
      );
  }
}
