import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RequisitionQuotation } from '../models/new-requisition-quotation.modal';
import { Form } from '@angular/forms';
import { FormDataToJsonService } from '@app/shared/service/form-data-to-json.service';

@Injectable({
  providedIn: 'root'
})
export class NewRequisitionQuotationService {
  // requisitionQuotationData = new BehaviorSubject<RequisitionQuotation>(null);

  constructor(private http: HttpClient,
    private dataToJson: FormDataToJsonService
  ) { }

  // public get requisitionQuotationValue(): RequisitionQuotation {
  //   return this.requisitionQuotationData.value;
  // }

  getVendorsByPort(portId) {
    return this.http.get<{ data }>(`${environment.apiUrl}/v1/company/vendors_by_port/${portId}`)
      .pipe(map(dataVal => {
        return dataVal.data;
      }));
  }

  getVendorPricesAndInventory(payload) {
    return this.http.post(`${environment.apiUrl}/v1/cart/get_requirements_prices_and_inventory`, payload);
  }

  moveItemsToCart(payload, extraData) {
    const dataToJson = this.dataToJson.convertFormDataToJson(payload, extraData);
    return this.http.post(`${environment.apiUrl}/v1/cart/move_requirement_to_cart`, dataToJson);
  }

  /**
   * @description This method is used to get the vendor prices and inventory status for RFQ parser
   * @param payload - The payload containing the data to be sent in the request body
   * @returns An Observable that emits the response from the server
  */
  rfqParserPricesWithInventoryStatus(payload) {
    return this.http.post(`${environment.apiUrl}/v1/cart/rfq_parser_prices_with_inventory_status`, payload)
  }

  uploadRequisitionQuotation(payload) {
    const headers = new HttpHeaders();
    headers.set('Content-Type', null);
    headers.set('Accept', 'multipart/form-data');
    return this.http.post<any>(`${environment.apiUrl}/v1/cart/import_requirements`,
      payload, {
      headers
    });
  }

  exportRequisitionQuotation(payload) {
    const header = new HttpHeaders().set('x-file', ['xlsx']);
    return this.http.post(`${environment.apiUrl}/v1/cart/export_requirements`, payload, { headers: header, responseType: 'blob' });
  }

  exportRfqReport(exports, query = '') {
    const header = new HttpHeaders().set('x-file', ['xlsx']);
    return this.http.get(`${environment.apiUrl}/v1/report/rfq_parser_report?export=${exports}` + `${query}`, { headers: header, responseType: 'blob' }).pipe(map(dataVal => {
      return dataVal;
    }));
  }
  placeRequisition(payload, extraData) {
    const dataToJson = this.dataToJson.convertFormDataToJson(payload, extraData);
    return this.http.post(`${environment.apiUrl}/v1/cart/convert_requirement_to_requisition`, dataToJson);
  }

  sendRequisitionEmail(payload) {
    return this.http.post(`${environment.apiUrl}/v1/cart/send_requirements_email`, payload);
  }


  orderCreateSuccessResponse(orderID) {
    return this.http.post(`${environment.apiUrl}/v1/order/send_rfq_for_requisition/${orderID}`, {})
  }
  // getrequisitionQuotationData(value) {
  //   this.requisitionQuotationData.next(value);
  // }
}
