import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InputTextModule } from 'primeng/inputtext';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthComponent } from './authentication/auth/auth.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { JwtInterceptor } from './authentication/helpers/jwt.interceptor';
import { ErrorInterceptor } from './authentication/helpers/error.interceptor';

import { DashboardModule } from './dashboard/dashboard.module';
import { LayoutModule } from './layout/layout.module';
import { SharedModule } from './shared/shared.module';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AuthGuard } from './authentication/helpers/auth.guard';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { RegisterComponent } from './register/register.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { HelpChatModule } from '@app/modules/help-chat/help-chat.module';
import { CommonModule, DATE_PIPE_DEFAULT_OPTIONS } from '@angular/common';
import { environment } from 'environments/environment';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { WebsiteMaintenanceComponent } from './website-maintenance/website-maintenance.component';
import { KycFormComponent } from './kyc-form/kyc-form.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { NewRequisitionQuotationComponent } from './modules/new-requisition-quotation/new-requisition-quotation.component';
import { ProductModule } from './modules/product/product.module';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { FidbackFormComponent } from './feedback/feedback.component';
import { NgSelectModule } from '@ng-select/ng-select';
/*declare module "@angular/core" {
  interface ModuleWithProviders<T = any> {
    ngModule: Type<T>;
    providers?: Provider[];
  }
} */
@NgModule({
  declarations: [AppComponent, AuthComponent, DashboardComponent, ForgetPasswordComponent,
    RegisterComponent, WebsiteMaintenanceComponent, KycFormComponent, PageNotFoundComponent, NewRequisitionQuotationComponent, FidbackFormComponent],
  imports: [CommonModule,
    NgSelectModule,
    BrowserAnimationsModule,
    BrowserModule,
    NgbModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    DashboardModule,
    LayoutModule,
    SharedModule,
    InputTextModule,
    ProductModule,
    ProgressbarModule,
    TabsModule.forRoot(),
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true
    }),
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: true }),
    AutoCompleteModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    HelpChatModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: { timezone: 'local' } },
    AuthGuard
  ],
  schemas: [NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
