import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import { NewDashboard } from "@app/dashboard/models/newdashboard.model";
import { FilterComponent } from "../filter/filter.component";
import { PanelComponent } from "../panel/panel.component";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { SharedService } from "@app/shared/service/shared.service";
import { ToastrService } from "ngx-toastr";
import { AlertService } from "@app/shared/service/alert.service";
import { DashboardService } from "@app/dashboard/dashboard.service";
import { UserRoles } from "@app/core/enums/user-roles";
import { ChartRedirectionService } from "@app/shared/service/chart-redirection.service";
import { ProductService } from "@app/modules/product/services/product.service";
@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css'],
})

export class TableComponent implements OnInit {
  @Input() title: string;
  @Input() data: NewDashboard;
  @ViewChild('panelData', { static: false }) panelData: PanelComponent;
  @ViewChild('filterData', { static: false }) filterData: FilterComponent;
  @ViewChild('tableData', { static: false }) tableDataModal: TemplateRef<any>;
  @Output() callParentMethodEvent = new EventEmitter<string>();
  @Output() passProduct = new EventEmitter<any>();
  modal: any;
  editPanel: boolean = false;
  sharedData: NewDashboard;
  // modalRef: BsModalRef;
  filterLength: boolean;
  role: any;
  userRoles = UserRoles;
  otherAxis;
  tempTableData;
  isDownLoading: boolean;
  disablePagination: boolean = true;
  deleteModal: BsModalRef;
  loadingDeletePanel: boolean;
  numberCounter: number = 0;
  isClear: boolean = false;
  constructor(private bsModalService: BsModalService,
    private sharedService: SharedService, private toastr: ToastrService,
    private altService: AlertService, private dashboardService: DashboardService,
    private chartRedirection: ChartRedirectionService,
    private productService: ProductService
  ) { }

  ngOnInit(): void {
    this.numberCounter = 0; // Reset counter when initializing
    this.role = this.sharedService.getUserRole();
    this.checkFilter();
    this.otherAxis = this.data;
    this.tempTableData = JSON.parse(JSON.stringify(this.data));
  }
  openModel() {
    this.onPageChange('other')
    this.modal = this.bsModalService.show(
      this.tableDataModal,
      Object.assign(
        { backdrop: 'static', class: 'gray modal-xl ' }
      ))
  }
  checkFilter() {
    this.filterLength = this.data?.filters.some(response => {
      return response.filter_value ? true : false;
    })
  }
  fetchData(value, index, prod, yIndex) {
    if (value.navigation_type[index]) {
      const budget = value.column_values[yIndex][0];
      switch (value.axes[index].axis_id) {
        case 62:
          this.productService.getByid(value.primary_axis_ids[yIndex].id).subscribe((response) => {
            this.passProduct.emit(response);
          })
          break;
        case 42:
          this.productService.getByid(value.primary_axis_ids[yIndex].id).subscribe((response) => {
            this.passProduct.emit(response);
          })
          break;
        case 5:
          this.chartRedirection.generateFilterBasedPayload(value, value.axes[index].axis_id, prod, value.primary_axis_ids[yIndex]);
          break;
        case 67:
          this.chartRedirection.generateFilterBasedPayload(value, value.axes[index].axis_id, prod, value.primary_axis_ids[yIndex]);
          break;
        case 6:
          this.chartRedirection.generateFilterBasedPayload(value, value.axes[index].axis_id, prod, value.primary_axis_ids[yIndex]);
          break;
        case 31:
          this.chartRedirection.generateFilterBasedPayload(value, value.axes[index].axis_id, prod, value.primary_axis_ids[yIndex]);
          break;
        case 7:
          this.chartRedirection.generateFilterBasedPayload(value, value.axes[index].axis_id, prod, value.primary_axis_ids[yIndex]);
          break;
        case 64:
          this.chartRedirection.generateFilterBasedPayload(value, value.axes[index].axis_id, prod, value.primary_axis_ids[yIndex]);
          break;
        case 60:
          this.chartRedirection.generateFilterBasedPayload(value, value.axes[index].axis_id, budget, value.primary_axis_ids[yIndex]);
          break;
        case 59:
          this.chartRedirection.generateFilterBasedPayload(value, value.axes[index].axis_id, budget, value.primary_axis_ids[yIndex]);
          break;
        case 61:
          this.chartRedirection.generateFilterBasedPayload(value, value.axes[index].axis_id, budget, value.primary_axis_ids[yIndex]);
          break;
        case 75:
          this.chartRedirection.generateFilterBasedPayload(value, value.axes[index].axis_id, budget, value.primary_axis_ids[yIndex]);
          break;
        case 78:
          this.chartRedirection.generateFilterBasedPayload(value, value.axes[index].axis_id, budget, value.primary_axis_ids[yIndex]);
          break;
        case 76:
          this.chartRedirection.generateFilterBasedPayload(value, value.axes[index].axis_id, budget, value.primary_axis_ids[yIndex]);
          break;
        case 32:
          this.chartRedirection.generateFilterBasedPayload(value, value.axes[index].axis_id, prod, value.primary_axis_ids[yIndex]);
          break;
        default:
          this.chartRedirection.generateFilterBasedPayload(value, value.axes[index].axis_id, prod, value.primary_axis_ids[yIndex]);
      }
    }
  }

  onPageChange(value) {
    let page;
    this.disablePagination = false;
    if (value === 'next') {
      page = this.tempTableData.page + 1;
    }
    else if (value === 'prev') {
      page = this.tempTableData.page - 1;
    }
    else {
      page = 1;
    }
    this.dashboardService.paginateTable(this.tempTableData.report_id, page).subscribe((response: NewDashboard) => {
      this.tempTableData = response?.data[0];
      this.disablePagination = true;
    }, error => {
      this.disablePagination = true;
    })
  }

  openFilterModal() {
    this.filterData.openModel(this.data.report_id);
  }

  // Adjusts alignment of each td element
  getAlignment(i) {
    if (typeof i == 'number') {
      return 'center';
    }
  }

  openPanelModal(isEdit = false) {
    this.editPanel = isEdit ? true : false;
    this.sharedData = this.data;
    this.panelData.openModel();
  }

  panelDeleteModal(template: TemplateRef<any>) {
    this.deleteModal = this.bsModalService.show(
      template,
      Object.assign({}, { class: "catalogue gray modal-sm" })
    );
    this.altService.backModalClose(this.deleteModal);
  }

  deletePanel() {
    this.loadingDeletePanel = true;
    this.sharedService.deletePanel(this.data?.report_id, 1).subscribe(res => {
      if (res) {
        this.toastr.success('Report Deleted Successfully!');
        this.dashboardService.deleteReport(true);
        this.callParentMethodEvent.emit(this.data?.report_id.toString());
        this.loadingDeletePanel = false;
        this.deleteModal.hide();
      }
    }, error => {
      this.toastr.error('Something went wrong!');
      this.loadingDeletePanel = false;
      this.deleteModal.hide();
    })
  }

  reloadPage(event) {
    this.callParentMethodEvent.emit(event);
  }

  async downloadReport() {
    this.isDownLoading = true;
    try {
      const res = await this.dashboardService.getReportData(this.data?.report_id, null, 1);
      this.sharedService.saveAsBlob(
        res,
        'application/vnd.ms-excel',
        `PO_${this.data?.report_title}.xlsx`
      );
      this.isDownLoading = false;
      this.toastr.success('Downloaded Successfully.');
    } catch (error) {
      this.isDownLoading = false;
      this.toastr.error('Failed to download report.');
    }
  }
  filterAppied(event) {
    if (this.data?.report_id) {
      this.data = event[0]
      this.dashboardService.deleteReport(false);
      this.callParentMethodEvent.emit(this.data?.report_id.toString());
      this.ngOnInit();
    }
  }

  numberValues = [];


  // Method to check if value is a string
  isString(value: any): boolean {
    return typeof value === 'string';
  }

  // Method to return the length of a string (or default colspan if not a string)

  isNumber(value: any): boolean {
    return typeof value === 'number';
  }

  getNumberValue(index: number): string {
    // Calculate the position in numberValues
    const numberIndex = this.tempTableData.column_values[0]
      .slice(0, index)
      .filter(v => this.isNumber(v)).length;
    return this.numberValues[numberIndex] || '';
  }

  getCombinedStringsAndColspan(index: number, values: any[]): { colspan: number, text: string } {
    let combinedText = '';
    let colspan = 0;
    for (let i = index; i < values.length; i++) {
      if (!this.isNumber(values[i])) {
        combinedText += values[i] + ' ';
        colspan++;
      } else {
        break;
      }
    }
    // Trim the combined text to remove any trailing spaces
    combinedText = combinedText.trim();
    this.calculateArry()
    return { colspan, text: combinedText };
  }

  calculateArry() {
    if (this.tempTableData.column_values.length == 1) {
      this.numberValues = this.tempTableData.column_values[0].filter(item => typeof item === 'number')
    } else {
      this.numberValues = this.tempTableData.column_values
        .map(row => row.filter(column => typeof column === 'number'))
        .filter(row => row.length > 0).reduce((acc, current) => {
          return acc.map((val, index) => val + current[index]);
        });
    }
    // console.log('numbers', this.numberValues)
  }

  removeFilter(event) {
    if (event) {
      this.isClear = true;
      this.sharedService.removeFilter(event);
      this.callParentMethodEvent.emit(event.report_id.toString());
      setTimeout(() => {
        this.isClear = false;
      }, 3000)
    }
  }
}