import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { FormDataToJsonService } from '@app/shared/service/form-data-to-json.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  // public getUserStatus = new BehaviorSubject(false);

  constructor(private http: HttpClient, private toast: ToastrService,
    private formDataToJson: FormDataToJsonService
  ) {
    this.currentUserSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  login(username: string, password: string) {
    return this.http
      .post<any>(`${environment.apiUrl}/v1/user/login`, {
        username,
        password
      })
      .pipe(
        map(user => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          if (user?.data?.user_details?.role_id == 6) {
            this.toast.error("Can't access for this user.");
          } else {
            localStorage.setItem('currentUser', JSON.stringify(user));
            this.currentUserSubject.next(user);
            return user;
          }
        })
      );
  }

  register(data) {
    const dataJson = this.formDataToJson.convertFormDataToJson(data);
    return this.http
      .post<any>(`${environment.apiUrl}/v1/user/registration_request`, dataJson)
      .pipe(
        map(user => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          /* localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user); */
          return user;
        })
      );
  }

  deleteLocalData() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('reqQuotationData');
    localStorage.removeItem('userProfileImage');
  }

  logout() {
    // remove user from local storage and set current user to null
    this.http
      .post(`${environment.apiUrl}/v1/user/logout/access`, {})
      .subscribe(data => { });
    this.deleteLocalData();
    localStorage.removeItem('ship_id');
    localStorage.removeItem('removeReport');
    this.currentUserSubject.next(null);
  }

  getToken() {
    // return localStorage.getItem('currentUser')
    const user = JSON.parse(localStorage.getItem('currentUser'));
    this.currentUserSubject.next(user);
    return user ? user.data.access_token : null;
  }

  getUserStatusValue(value) {
    // this.getUserStatus.next(value);
  }

  productHandler(jsonPayload) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const body = {}
    return this.http.post(`${environment.apiUrl}/v1/user/user_catalogue_cred`, jsonPayload, { headers });
  }
}
