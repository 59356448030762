import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableColumn, DataTablePagination } from '@app/core/models/data-table';
import { PaginationData } from '@app/core/models/response.model';
import { DashboardService } from '@app/dashboard/dashboard.service';
import { CompanyService } from '@app/modules/company/services/company.service';
import { CommonService } from '@app/shared/helper/common';
import { FeedbackForm } from '../Order.model';
import { HttpParams } from '@angular/common/http';
import { FeedbackFormComponent } from '../feedbackform/feedbackform.component';
import { UserRoles } from '@app/core/enums/user-roles';
import { SharedService } from '@app/shared/service/shared.service';
import { TitleCasePipe } from '@angular/common';
import { S3Service } from '@app/shared/service/aws-bucket.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-feedback-form-listing',
  templateUrl: './feedback-form-listing.component.html',
  styleUrls: ['./feedback-form-listing.component.css']
})
export class FeedbackFormListingComponent implements OnInit {
  isFeedbackFormModelOpen: boolean = false;
  @ViewChild('formSubmissionTable', { static: false }) table;
  page = new DataTablePagination();
  filterForm: FormGroup;
  searchForm: FormGroup;
  keyPressed: String = '';
  currentUrl: any;
  pageCount = 0;
  clickedPage: any;
  rows = new Array();
  isLoading = true;
  loading: boolean;
  columns: DataTableColumn[];
  totalStars: number = 5;
  starIndexes: number[] = Array.from({ length: this.totalStars }, (_, i) => i);
  formId: number
  role: any;
  userRole = UserRoles
  @ViewChild('feedbackModal', { static: false }) feedbackModal: FeedbackFormComponent;
  env;
  constructor(private dashboardService: DashboardService,
    private formBuilder: FormBuilder,
    private activeRoute: ActivatedRoute,
    private companyService: CompanyService,
    private commonService: CommonService,
    private titleCasePipe: TitleCasePipe,
    private router: Router, private sharedService: SharedService,
    private s3Service: S3Service) {
    this.env = environment
    this.page.limit = 10;
    this.page.offset = 0;
    this.page.count = 0;
  }

  ngOnInit(): void {
    this.role = this.sharedService.getUserRole();
    this.filterForm = this.formBuilder.group({
      name: ''
    });

    this.activeRoute.queryParams.subscribe(params => {
      this.formId = params['formId'];
      if (params.filter) {
        const filter: any = decodeURIComponent(params.filter);
        this.keyPressed = JSON.parse(filter).name;
      } else {
        this.keyPressed = null;
      }
      this.currentUrl = { page: params.page, filter: params.filter };
      this.companyService.getCurrentUrl(this.currentUrl);
      if (this.pageCount > 0) {
        this.pageCount = 0;
        return;
      } else {
        this.setPage(this.page);
      }
    });
  }

  onReset() {
    this.searchForm.patchValue({
      search: ''
    });
    this.commonService.buildFilterUrlParm(this.filterForm);

    setTimeout(() => {
      this.setPage(this.page);
    });
  }

  setPage(page: DataTablePagination) {
    this.isLoading = true;
    const pageNo = page.offset + 1;
    let params = new HttpParams()
      .set('page', `${pageNo}`)
      .set('limit', `${page.limit}`);
    this.getData(params);
    // table scroll on pagination change
    const scrollToTop = window.setInterval(() => {
      const pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 5);
  }

  onPageChange($event, count) {
    console.log($event, count)
    this.clickedPage = $event.page;
    this.pageCount = count;
  }

  goPreviousUrl() {
    this.router.navigateByUrl('/')
  }

  getData(params) {
    this.page = this.clickedPage ? this.page : this.commonService.CheckPage(this.page);
    const pageNo = this.page.offset + 1;
    this.dashboardService.getListOfForms(this.formId, 0, params).subscribe((data: PaginationData) => {
      this.rows = data.results.map((response: FeedbackForm) => {
        let user: string;
        if (response.to_user) {
          user = `${response.to_user?.fname} ${response.to_user?.lname ? response.to_user?.lname : ''}`
        } else {
          user = response?.form_elements_data?.filter((ele, i) => i < 3)
        }
        const rowData = {
          form_name: this.titleCasePipe.transform(response?.form_name),
          ship_name: this.titleCasePipe.transform(response?.order_details?.ship_detail),
          port_name: response?.order_details?.port_name,
          company_name: this.titleCasePipe.transform(response?.order_details?.company_name),
          delivery_date: new Date(),
          order_number: response?.order_details?.order_number,
          rating_data: response?.rating_data,
          to_user_id: response?.to_user_id,
          order_id: response?.order_id ? response?.order_id : 0,
          form_id: response?.form_id,
          created_at: response?.created_at,
          username: user,
          guestId: response.guest_id ? response.guest_id : null,
          attachment: response?.attachment
        };
        return rowData;
      });
      this.page.count = data.total;
      this.isLoading = false;
    })
  }

  isFilled(index: number, rating: number): boolean {
    return index < Math.floor(rating);
  }

  async redirectUser(value) {
    const img = await this.s3Service.getS3Object(this.env.bucket, value[0].url).then(res => res);
    window.open(img, "_blank");
  }

  isPartiallyFilled(index: number, rating: number): boolean {
    const decimalPart = rating - Math.floor(rating);
    return index === Math.floor(rating) && decimalPart > 0;
  }

  openModal(formId, orderId, userId, guestid) {
    if (this.isFeedbackFormModelOpen) {
      return;
    }
    this.isFeedbackFormModelOpen = true;
    if (this.formId == 74) {
      this.feedbackModal.openModal(formId, orderId, userId);
    } else {
      if (guestid) {
        userId = 0;
      }
      this.feedbackModal.openModal(formId, 0, userId, guestid);
    }
  }
}
