import { Component, OnInit, Input, TemplateRef, ViewChild, ChangeDetectorRef, Output, EventEmitter } from "@angular/core";
import { NewDashboard } from "@app/dashboard/models/newdashboard.model";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { FilterComponent } from "../filter/filter.component";
import { AlertService } from "@app/shared/service/alert.service";
import { SharedService } from "@app/shared/service/shared.service";
import { ToastrService } from "ngx-toastr";
import { PanelComponent } from "../panel/panel.component";
import { Router } from "@angular/router";
import { DashboardService } from "@app/dashboard/dashboard.service";
import { ChartRedirectionService } from "@app/shared/service/chart-redirection.service";
@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})

export class CardComponent implements OnInit {

  @Input() data: NewDashboard[];
  modal: BsModalRef
  index: number = null;
  shippingList = []
  @ViewChild(FilterComponent) filterData: FilterComponent;
  @ViewChild('panelData') panelData: PanelComponent;
  @Output() callParentMethodEvent = new EventEmitter<string>();
  editPanel: boolean;
  sharedData: NewDashboard;
  // modalRef: BsModalRef;
  filterList = [];
  filterArray;
  deleteCardModal;
  loadingDeleteModal: boolean;
  constructor(private cdr: ChangeDetectorRef,
    private modalService: BsModalService,
    private router: Router,
    private altService: AlertService,
    private sharedService: SharedService,
    private toastr: ToastrService,
    private dashboardService: DashboardService,
    private redirectionService: ChartRedirectionService) { }

  ngOnInit(): void {
  }

  checkData(index, val) {
    if (index >= 0) {
      this.filterList = this.data[index].filters.filter(data => {
        return data.filter_value ? true : false;
      });
      if (val == 1) {
        return this.filterList.length > 0 ? true : false;
      }
      else {
        return (this.filterList.map(response => {
          return `${response.filter.name}: ${response.filter_value_in_str}`;
        })).join(', ')
      }
    }
  }

  reloadPage(event) {
    this.callParentMethodEvent.emit(event);
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  /* Open Filter Modal Method*/
  openFilterModal(index: number) {
    this.index = index;
    setTimeout(() => {
      this.filterData.openModel(this.data[index].report_id);
    }, 100)
  }

  fetchValue(val) {
    if (val.navigation_type)
      this.redirectionService.generateFilterBasedPayload(val, val.axes[0].axis_id);
  }

  openPanelModal(index, isEdit = false) {
    this.editPanel = isEdit ? true : false;
    this.sharedData = this.data[index];
    setTimeout(() => {
      this.panelData.openModel();
    }, 100)
  }

  panelDeleteModal(indexId, template: TemplateRef<any>) {
    this.index = indexId;
    this.deleteCardModal = this.modalService.show(
      template,
      Object.assign({}, { class: "catalogue gray modal-sm" })
    );
    this.altService.backModalClose(this.deleteCardModal);
  }

  deletePanel() {
    this.loadingDeleteModal = true;
    this.sharedService.deletePanel(this.data[this.index].report_id, 1).subscribe(res => {
      if (res) {
        this.dashboardService.deleteReport(true);
        this.callParentMethodEvent.emit(this.data[this.index].report_id.toString());
        this.loadingDeleteModal = false;
        this.deleteCardModal.hide();
        this.toastr.success('Report Deleted Successfully!');
      }
    }, error => {
      this.toastr.error('Something went wrong!')
      this.loadingDeleteModal = false;
      this.deleteCardModal.hide();
    })
  }

  filterAppied(event) {
    if (this.data[this.index].report_id) {
      this.data[this.index] = event[0];
      this.dashboardService.deleteReport(false);
      this.callParentMethodEvent.emit(this.data[this.index].toString());
      this.ngOnInit();
    }
  }
}