import { environment } from 'environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import {
  CompanySpecificChargeRequest
} from "@app/modules/company/components/delivery-charges/delivery-charges.component";
import { FormDataToJsonService } from '@app/shared/service/form-data-to-json.service';

@Injectable({ providedIn: 'root' })
export class CompanyService {
  getUrl = new BehaviorSubject(null);

  constructor(private http: HttpClient,
    private formDataToJson: FormDataToJsonService
  ) { }

  /**
   * getAll(): method used for shipping company data based on type = 1 for shipping company and type=2 for vendor company
   * page: api is paginated
   * isDropdownSelection: its used for dropdownselection or not 1 for selection
   * details: used for either listing or for dropdown
    */
  getAll(page: number, limit: number, type = 1, isDropdownSelection = 1, headers = null, details = 1) {
    let params;
    if (type > 0) {
      params = new HttpParams()
        .set('page', page.toString())
        .set('limit', limit.toString())
        .set('type', type.toString())
        .set('is_dropdown_selection', isDropdownSelection.toString());
    } else {
      params = new HttpParams()
        .set('page', page.toString())
        .set('limit', limit.toString())
        .set('is_dropdown_selection', isDropdownSelection.toString());
    }
    params = params.append('details', details.toString());

    params.delete('type');

    return this.http
      .get<{ data }>(`${environment.apiUrl}/v1/company/improvement`, { params, headers })
      .pipe(
        map(dataVal => {
          return dataVal.data;
        })
      );
  }

  addCompany(company) {
    const headers = new HttpHeaders();
    headers.set('Content-Type', null);
    headers.set('Accept', 'multipart/form-data');
    return this.http.post<any>(`${environment.apiUrl}/v1/company/`, company, {
      headers
    });
  }

  deleteCharges(costId) {
    return this.http.delete<any>(`${environment.apiUrl}/v1/company/charges/${costId}`, {});
  }
  deleteSourcingLocationCharges(costId) {
    return this.http.delete<any>(`${environment.apiUrl}/v1/company/sourcing_location_wise_charges/${costId}`, {});
  }
  deleteSpecificCharges(vendorId, portId) {
    return this.http.delete<any>(`${environment.apiUrl}/v1/company/company_specific_transportation_charges/${portId}`, {});
  }
  editCompany(company, id: number) {
    const headers = new HttpHeaders();
    headers.set('Content-Type', null);
    headers.set('Accept', 'multipart/form-data');
    return this.http.put(`${environment.apiUrl}/v1/company/${id}`, company, {
      headers
    });
  }

  // Calls Post API To Submit Fleet Details
  fleetDetails(data, additionalData) {
    const formDataToJson = this.formDataToJson.convertFormDataToJson(data, additionalData);
    return this.http.post(`${environment.apiUrl}/v1/company/fleets`, formDataToJson);
  }

  viewFleetDetails(company_id) {
    return this.http.get<{ data }>(`${environment.apiUrl}/v1/company/fleets/${company_id}`).pipe(
      map(dataVal => {
        return dataVal.data;
      })
    );
  }

  getByid(id: number) {
    return this.http
      .get<{ data }>(`${environment.apiUrl}/v1/company/${id}`)
      .pipe(
        map(dataVal => {
          return dataVal.data;
        })
      );
  }

  getVendors(page: number, limit: number, details: number = 1) {
    return this.http
      .get<{ data }>(
        `${environment.apiUrl}/v1/company/improvement?page=${page}&limit=${limit}&type=2&is_dropdown_selection=1&details=${details}`
      ).pipe(
        map(dataVal => {
          return dataVal.data;
        })
      );
  }

  getCategoryByID(id) {
    return this.http.get<{ data }>(`${environment.apiUrl}/v1/company/${id}/category`).pipe(
      map(dataVal => {
        return dataVal.data;
      })
    );;
  }

  addCompanyDoc(document) {
    const headers = new HttpHeaders();
    headers.set('Content-Type', null);
    headers.set('Accept', 'multipart/form-data');
    return this.http.post<any>(`${environment.apiUrl}/v1/company/doc`, document, {
      headers
    });
  }
  deleteCompanyDoc(id) {
    return this.http.delete(`${environment.apiUrl}/v1/company/doc/${id}`);
  }

  getCompanySuggestion(search) {
    const url = `${environment.apiUrl}/v1/company/search/${search}`;
    return this.http.get<{ data }>(url).pipe(
      map(dataVal => {
        return dataVal;
      })
    );
  }

  getVendorSuggestion(search) {
    const url = `${environment.apiUrl}/v1/company/vendor_search/${search}`;
    return this.http.get<{ data }>(url).pipe(
      map(dataVal => {
        return dataVal;
      })
    );
  }

  getApproval() {
    return this.http.get<{ data }>(`${environment.apiUrl}/v1/user/assign_ship_status`).pipe(
      map(dataVal => {
        return dataVal.data;
      })
    );
  }

  approveShip(payload) {
    return this.http.put<{ data }>(`${environment.apiUrl}/v1/user/assign_ship_status`, payload).pipe(map(dataVal => dataVal.data));
  }

  getCategories(id) {
    return this.http
      .get<{ data }>(`${environment.apiUrl}/v1/company/${id}/category`)
      .pipe(
        map(dataVal => {
          return dataVal.data;
        })
      );
  }

  getSubCategories(id) {
    return this.http
      .get<{ data }>(`${environment.apiUrl}/v1/company/${id}/sub-category`)
      .pipe(
        map(dataVal => {
          return dataVal.data;
        })
      );
  }

  exportAll(type = 1, headers = null) {
    const params = new HttpParams().set('type', type.toString());
    return this.http
      .get(`${environment.apiUrl}/v1/company/export?`, { params, headers, responseType: 'blob' })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getAllPortCharges(page: number, limit: number, companyId: number, search: string = '') {
    return this.http
      .get<{ data }>(
        `${environment.apiUrl}/v1/company/charges?page=${page}&limit=${limit}&company_id=${companyId}&search=${search}`
      ).pipe(map(dataVal => {
        return dataVal.data;
      }));
  }

  getAllSourcingLocationCharges(page: number, limit: number, vendor_id: number, search: string = '') {
    return this.http
      .get<{ data }>(
        `${environment.apiUrl}/v1/company/sourcing_location_wise_charges?page=${page}&limit=${limit}&vendor_id=${vendor_id}&search=${search}`
      ).pipe(map(dataVal => {
        return dataVal.data;
      }));
  }

  addPortCharges(companyId: number, portId: number, currency, deliveryCharge, loungeCharge, delivery_charge_tax, launch_charge_tax, min, max, type, costId = null) {
    const headers = { 'content-type': 'application/json' }
    if (type == 1) {
      return this.http.post<any>(`${environment.apiUrl}/v1/company/charges?company_id=${companyId}&port_id=${portId}&delivery_charge=${deliveryCharge}&launch_charge=${loungeCharge}&currency=${currency}&delivery_charge_tax=${delivery_charge_tax}&launch_charge_tax=${launch_charge_tax}&min_order_value=${min}&max_order_value=${max}`
        , { headers: headers });
    }
    else if (type == 2) {
      return this.http.put<any>(`${environment.apiUrl}/v1/company/charges?company_id=${companyId}&port_id=${portId}&delivery_charge=${deliveryCharge}&launch_charge=${loungeCharge}&currency=${currency}&delivery_charge_tax=${delivery_charge_tax}&launch_charge_tax=${launch_charge_tax}&min_order_value=${min}&max_order_value=${max}&cost_id=${costId}`
        , { headers: headers });
    }
  }

  adEditSourceLocationCharges(companyId: number, portId: number, sourceId: number, currency, deliveryCharge, delivery_charge_tax, type, costId = null) {
    const headers = { 'content-type': 'application/json' }
    if (type == 1) {
      return this.http.post<any>(`${environment.apiUrl}/v1/company/sourcing_location_wise_charges?vendor_id=${companyId}&port_id=${portId}&delivery_charge=${deliveryCharge}&currency=${currency}&delivery_charge_tax=${delivery_charge_tax}&source_id=${sourceId}`
        , { headers: headers });
    }
    else if (type == 2) {
      return this.http.put<any>(`${environment.apiUrl}/v1/company/sourcing_location_wise_charges?vendor_id=${companyId}&port_id=${portId}&delivery_charge=${deliveryCharge}&currency=${currency}&delivery_charge_tax=${delivery_charge_tax}&source_id=${sourceId}&cost_id=${costId}`
        , { headers: headers });
    }
  }

  getVendorList(id) {
    return this.http.get<{ data }>(`${environment.apiUrl}/v1/company/order_vendor_full_list/${id}`).pipe(map(dataVal => {
      return dataVal.data;
    }));
  }

  getCurrentUrl(value) {
    this.getUrl.next(value);
  }

  blackListVendor(vendor_id, blacklist, body = null) {
    const headers = {
      'Content-Type': 'application/json'
    };
    return this.http.put(`${environment.apiUrl}/v1/company/blacklist_vendor/${vendor_id}/${blacklist}`, body, { headers });
  }

  addCompanySpecificTransportCharges(payload: CompanySpecificChargeRequest) {
    return this.http.post(`${environment.apiUrl}/v1/company/company_specific_transportation_charges`, { ...payload });
  }

  getCompanySpecificTransportCharges(companyId: string, vendorId: string) {
    return this.http.get<{ data }>(`${environment.apiUrl}/v1/company/company_specific_transportation_charges/${vendorId}/${companyId}`).pipe(map(dataVal => dataVal.data));
  }
  /** Company Specific IMPORT API */

  importCompanySpecific(payload) {
    const headers = new HttpHeaders();
    headers.set('Content-Type', null);
    headers.set('Accept', 'multipart/form-data');
    return this.http.post(`${environment.apiUrl}/v1/company/company_specific_catalogue`, payload, { headers })
  }

  /**
   * download company specific catalogue report
    */
  exportCompanySpecific(comapnyId) {
    const header = new HttpHeaders().set('x-file', ["xlsx"]);
    return this.http.get(`${environment.apiUrl}/v1/company/company_specific_catalogue/${comapnyId}`, { headers: header, responseType: 'blob' })
  }

  /**
   * getVendorDetailsById
   * @param: vendorId,page,limit,details,type
    */
  getVendorDetailsByID(vendorId: number, page: number, limit: number, type: number) {
    return this.http.get<{ data }>(`${environment.apiUrl}/v1/company/improvement/${vendorId}?page=${page}&limit=${limit}&type=${type}&is_dropdown_selection=1&details=${0}`).pipe(map(dataVal => dataVal.data))
  }
}
